<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-row class="mx-2">
          <v-col cols="12" md="3" lg="3">
            <label class="label-input">{{
              $t("ReportScanInOut.textsearchByname")
            }}</label>
            <v-text-field outlined dense v-model="filter" prepend-inner-icon="mdi-magnify"
              @keypress.enter="searchFilterItem">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="2" lg="2">
            <label class="label-input">{{ $t("e-approve.date_start") }}</label>
            <DatePicker class="date-time" style="width: 100%" type="date" v-model="start_date" valueType="format"
              slot="activator" :append-to-body="true" name="founding_date" @change="searchFilterItem()"></DatePicker>
          </v-col>
          <v-col cols="12" md="2" lg="2">
            <label class="label-input">{{ $t("e-approve.date_end") }}</label>
            <DatePicker class="date-time" style="width: 100%" type="date" v-model="end_date" valueType="format"
              slot="activator" :append-to-body="true" name="founding_date" @change="searchFilterItem()"></DatePicker>
          </v-col>
          <v-col cols="12" md="2" lg="2">
            <label class="label-input">{{
              $t("e-approve.label_select")
            }}</label>
            <v-select :items="todoStatus" outlined dense item-value="value" item-text="name" v-model="status"
              @change="searchFilterItem()"></v-select>
            <!-- @change="fetchEmployeeHourTotal" -->
          </v-col>
          <v-col cols="6" md="3" lg="2" sm="6" class="mb-3">
            <v-btn class="mt-md-7 mr-md-2 btn-create lfont btn" color="info" @click="onCreate">
              <v-icon left>
                mdi-plus-box-multiple
              </v-icon>
              {{ $t("e-approve.btn_create") }}</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="section-form-content">
              <div class="block-content">
                <table v-if="listEmployeeApprove.length > 0" style="max-width: 100% !important;">
                  <thead>
                    <tr>
                      <th class="text-left">
                        ວັນທີ
                      </th>
                      <th class="text-left">
                        ເລກຂໍອະນຸມັດ
                      </th>
                      <th class="text-left">
                        ຫົວຂໍ້
                      </th>
                      <th class="text-left">
                        ລາຍລະອຽດ
                      </th>
                      <th class="text-left">
                        ໄຟລ໌ເອກະສານ
                      </th>
                      <th class="text-left">
                        ຜູ້ອະນຸມັດ
                      </th>
                      <th class="text-left">
                        ສະຖານະ
                      </th>
                      <th class="text-left">
                        ເຄື່ອງມື
                      </th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr v-for="(item, idx) in listEmployeeApprove" :key="idx">
                      <td class="pa-3">
                        {{ moment(item.created_time).format("YYYY-MM-DD") }}
                        {{ moment(item.created_time).format("HH:mm:ss") }}
                      </td>
                      <td class="pa-3">{{ item.approval_number }}</td>
                      <td class="pa-3 lfont">{{ item.title }}</td>
                      <td class="pa-3 lfont">{{ item.description }}</td>
                      <td class="pa-3">
                        <v-icon left @click="openFile(item.file_name)">
                          mdi-file-eye
                        </v-icon>
                      </td>
                      <td class="pa-3">
                        <p v-for="(approve_by_list, index) in item.approved_bys" class="lfont">
                          {{ approve_by_list.name }} {{ approve_by_list.surname }}
                          <v-chip small style="background-color: orange !important; color: #fff !important;"
                            v-if="approve_by_list.status == 'pending'">
                            pending
                          </v-chip>
                          <v-chip style="background-color: #0336ff !important; color: #fff !important;" small
                            v-if="approve_by_list.status == 'approved'">
                            Approved
                          </v-chip>
                          <v-chip small style="background-color: red !important; color:#fff !important;"
                            v-if="approve_by_list.status == 'rejected'">
                            rejected
                          </v-chip>
                          <br />
                          <span v-if="approve_by_list.refer_number" class="lfont font-weight-thin"
                            style="font-size:80%;">ລະຫັດອ້າງອີງ:
                          </span>
                          <span v-if="approve_by_list.refer_number != null" style="font-size:80%; color:#0336ff">{{
                            approve_by_list.refer_number }}</span>
                          <br /><span v-if="approve_by_list.refer_number != null ||
                            approve_by_list.status == 'rejected'
                            " class="lfont font-weight-thin" style="font-size:80%;">ວັນທີອະນຸມັດ:
                          </span>
                          <span v-if="approve_by_list.refer_number != null ||
                            approve_by_list.status == 'rejected'
                            ">
                            <span style="font-size:80%; color:blue" v-if="approve_by_list.status == 'approved'">{{
                              moment(approve_by_list.approve_time).format(
                                "YYYY-MM-DD"
                              )
                            }}
                              {{
                                moment(approve_by_list.approve_time).format(
                                  "HH:mm:ss"
                                )
                              }}</span>

                            <span style="font-size:80%; color:red" v-if="approve_by_list.status == 'rejected'">{{
                              moment(approve_by_list.approve_time).format(
                                "YYYY-MM-DD"
                              )
                            }}
                              {{
                                moment(approve_by_list.approve_time).format(
                                  "HH:mm:ss"
                                )
                              }}</span>
                          </span>
                          <span v-else class="font-weight-thin">******</span>
                        </p>
                      </td>
                      <td class="pa-3">
                        <span v-if="item.status == 'pending'" style="color: orange;">{{ item.status }}</span>
                        <span v-if="item.status == 'rejected'" style="color: red;">{{ item.status }}
                          <p v-for="(approve_by_list,
                            index) in item.approved_bys">
                            <span v-if="approve_by_list.status == 'rejected'">
                              <span style="color:black !important;">Note :</span>
                              <span> {{ approve_by_list.remark }}</span>
                            </span>
                          </p>
                        </span>
                        <span v-if="item.status == 'approved'" style="color:#0336ff;">{{ item.status }}</span>
                        <span v-if="item.status == 'success'" style="color: green;">{{ item.status }}</span>
                      </td>
                      <td class="pa-3">
                        <span v-if="item.approved_bys[0].status != 'approved' &&
                          item.approved_bys[0].status != 'rejected'
                          ">
                          <v-btn text color="success accent-4" @click="onEdit(item.id)">
                            Edit
                          </v-btn>
                          <v-btn text color="error accent-4" @click="onDeleted(item.id)">
                            Delete
                          </v-btn>
                        </span>
                        <span v-else>
                          <v-btn text disabled color="success accent-4" @click="onEdit(item.id)">
                            Edit
                          </v-btn>
                          <v-btn text disabled color="error accent-4" @click="onDeleted(item.id)">
                            Delete
                          </v-btn>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <defaultTableNoResult v-else />

                <Loading v-if="isLoading" />
              </div>
            </div>

            <ModalDelete fullscreen title="Edit Employee Leave">
              <template v-slot="{ close }">
                <Delete :eApprove_id="eApprove_id" @close="close" @success="fetchEmployeeApprove()" />
              </template>
            </ModalDelete>

            <!-- <update :editData="editData" /> -->
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";
import Loading from "@/components/Loading";
import Delete from "./crud/Delete";
import update from "./crud/update";

export default {
  components: {
    DatePicker,
    Pagination,
    defaultTableNoResult,
    Loading,
    Delete,
    update,
  },
  data() {
    return {
      end_date: "",
      start_date: "",
      status: "all",
      filter: "",
      listEmployeeApprove: [],
      editData: [],
      isLoading: false,

      offset: 10,
      pagination: {},
      per_page: 10,
      eApprove_id: "",
      todoStatus: [
        {
          id: 0,
          name: this.$t("statusEleave.all"),
          value: "all",
        },
        {
          id: 1,
          name: this.$t("statusEleave.pending"),
          value: "pending",
        },
        {
          id: 2,
          name: this.$t("statusEleave.approved"),
          value: "approved",
        },
        {
          id: 3,
          name: this.$t("statusEleave.success"),
          value: "success",
        },
        {
          id: 3,
          name: this.$t("statusEleave.rejected"),
          value: "rejected",
        },
      ],
    };
  },
  methods: {
    openFile(url) {
      window.open(url, "_blank", "noreferrer");
    },
    onCreate() {
      this.$router
        .push({
          name: "employee_e_approve.create",
        })
        .catch(() => { });
    },

    onDeleted(approve_id) {
      this.eApprove_id = approve_id;
      this.$store.commit("modalDelete_State", true);
    },

    onEdit(approval_id) {
      this.editData = this.filterApproval(approval_id);
      console.log("edit Data", this.editData);
      // return;
      this.$router
        .push({
          name: "employee_e_approve.update",
          params: {
            id: approval_id,
            editData: this.editData,
          },
        })
        .catch(() => { });
    },
    filterApproval(approval_id) {
      return (
        this.listEmployeeApprove.filter((item) => {
          return item.id == approval_id;
        })[0] || {}
      );
    },

    searchFilterItem() {
      this.fetchEmployeeApprove();
      // this.pagination.current_page = 1;
    },

    fetchEmployeeApprove() {
      this.isLoading = true;
      const items = {
        status: this.status,
        start_date: this.start_date,
        end_date: this.end_date,
        filter: this.filter,
      };
      this.$axios
        .post(`/employee/list/approvals`, items)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.isLoading = false;
            this.listEmployeeApprove = res.data.data;
            // this.pagination = res.data.data.pagination;
            // if (!this.listEmployeeApprove.length > 0) {
            //   this.pagination.current_page = this.pagination.current_page - 1;
            // }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchEmployeeApprove();
  },
};
</script>

<style lang="scss" scoped>
.table-body {
  tr {
    td {
      text-wrap: balance;
    }
  }
}

.custom_table {
  margin-top: 10px;
  border: 1px solid rgb(131, 128, 128);
  width: auto;
  height: auto;
  padding: 5px;
  overflow-y: scroll;
}
</style>
